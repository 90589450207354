import { Helmet } from 'react-helmet-async';
// @mui
import {Container} from '@mui/material';
import QuestionEditor from '../components/QuestionEditor';

export default function QuizEditorPage(){
    return (
      <>
      <Helmet>
        <title> Quiz Editor </title>
      </Helmet>

      <div className="App">
        <QuestionEditor/>
      </div>
    </>
      );
}