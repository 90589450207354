import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    userid: null,
    displayname: "",
    email: "",
    loading: false,
    checkingAuth: true,
    error: null,
  },
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      const { userid, displayname, email } = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.userid = userid;
      state.displayname = displayname;
      state.email = email;
      state.checkingAuth = false;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.checkingAuth = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userid = null;
      state.displayname = "";
      state.email = "";
      state.checkingAuth = false;
    },
    setCheckingAuth: (state, action) => {
      state.checkingAuth = action.payload; // Directly set the checkingAuth state
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  setCheckingAuth,
} = userSlice.actions;

export const selectDisplayName = (state) => state.user.displayname;
export const selectEmail = (state) => state.user.email;

export default userSlice.reducer;
