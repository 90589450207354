import { db } from "../firebase";

const InitNewUser = (userId) => {
  //const userCollection = db.collection('Quizlets').doc(userId).collection('myquiz');
  //userCollection.doc().set({});
  const quizRef = db.collection("Quizlets").doc(userId).collection("myquiz");
  quizRef
    .get()
    .then((snapshot) => {
      // Check if the myquiz collection already exists for the user
      if (snapshot.empty) {
        // Create the new sample quiz
        const now = new Date();
        quizRef
          .add({
            name: "US History",
            description: "A sample quiz of US History",
            createdon: now,
            modifiedon: now,
            questionCount: 5,
          })
          .then((docRef) => {
            console.log("New quiz created with ID:", docRef.id);

            // Add sample questions and answers
            const quizId = docRef.id;
            const questionsRef = quizRef.doc(quizId).collection("items");
            const sampleQuestions = [
              {
                question: "Who was the first President of the United States?",
                answer: "George Washington",
              },
              {
                question:
                  "Which document established the fundamental laws and principles of the United States?",
                answer: "The Constitution",
              },
              {
                question:
                  "What year was the Declaration of Independence signed?",
                answer: "1776",
              },
              {
                question: "Who wrote the Star-Spangled Banner?",
                answer: "Francis Scott Key",
              },
              {
                question:
                  "Which state was the 50th state to join the United States?",
                answer: "Hawaii",
              },
            ];

            sampleQuestions.forEach((sampleQuestion) => {
              questionsRef
                .add(sampleQuestion)
                .then(() => {
                  console.log("Sample question added");
                })
                .catch((error) => {
                  console.error("Error adding sample question:", error);
                });
            });
          })
          .catch((error) => {
            console.error("Error creating new quiz:", error);
          });
      }
    })
    .catch((error) => {
      console.error("Error fetching myquiz collection:", error);
    });
};

export default InitNewUser;
