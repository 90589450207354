import React, { useEffect } from "react"
import * as firebaseui from "firebaseui"
import "firebaseui/dist/firebaseui.css"
import firebase from "firebase/compat/app"

import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../../reducers/userSlice';
import { useNavigate } from 'react-router-dom';

import { db } from '../../../firebase';
import InitNewUser from "../../../components/InitNewUser";

// ----------------------------------------------------------------------

export default function LoginFormFirebaseUI() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const ui = firebaseui.auth.AuthUI.getInstance() ||
    new firebaseui.auth.AuthUI(firebase.auth());

    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInSuccessUrl: "/dashboard",
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          const user = authResult.user;

          const isNewUser = authResult.additionalUserInfo.isNewUser;
          if (isNewUser) {
            // New user signed in
            console.log('New user signed in');
            InitNewUser(user.uid);
          } else {
            // Existing user signed in
            InitNewUser(user.uid);
            console.log('Existing user signed in');
          }

          // Dispatch the loginSuccess action with the userid
          dispatch(loginSuccess(
            {
              userid: user.uid,
              displayname: user.displayName,
              email: user.email
            }));

          navigate("/dashboard");
          // Return false to prevent the default redirect behavior
          return false;
        }
      }
      // Other config options...
    });
  }, [dispatch]);

  return (
    <>
      <div id='firebaseui-auth-container'/>
    </>
  );
}
