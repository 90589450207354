import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA9ukkwH7HdbOAaDOiIHsaHBy88dlPX8z8",
  authDomain: "quiz-react-firebase-a6893.firebaseapp.com",
  projectId: "quiz-react-firebase-a6893",
  storageBucket: "quiz-react-firebase-a6893.appspot.com",
  messagingSenderId: "249559732591",
  appId: "1:249559732591:web:26a17eff12125b42f8589a",
  measurementId: "G-FYPL62QDY6",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).catch((error) => {
  console.error("Firebase auth persistence error:", error);
});

export { db, auth };
