import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Tooltip,
  Box,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SchoolIcon from "@mui/icons-material/School";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import QuizIcon from "@mui/icons-material/Quiz";
import ListAltIcon from "@mui/icons-material/ListAlt"; // Icon for "Items"
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../firebase";
import { useSelector } from "react-redux";
import { format } from "date-fns"; // Assuming you're using date-fns for date formatting
import LoadingScreen from "./LoadingScreen";

const QuizList = () => {
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noQuestionsDialogOpen, setNoQuestionsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // "create" or "edit"
  const [editQuiz, setEditQuiz] = useState({
    name: "",
    description: "",
    id: "",
  });
  const userid = useSelector((state) => state.user.userid);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [quizToDelete, setQuizToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const quizRef = db.collection("Quizlets").doc(userid).collection("myquiz");
    const unsubscribe = quizRef.onSnapshot(
      (snapshot) => {
        const quizzes = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            modifiedon: doc.data().modifiedon
              ? doc.data().modifiedon.toDate()
              : new Date("1995-12-17T03:24:00"),
          }))
          .sort((a, b) => b.modifiedon.getTime() - a.modifiedon.getTime());

        setQuizData(quizzes);
        setLoading(false); // Data has been fetched
      },
      (error) => {
        console.error("Error fetching quiz data:", error);
        setLoading(false); // Handle error state
      }
    );

    return () => unsubscribe();
  }, [userid]);

  const handleFeatureAccess = (quiz, featurePath) => {
    if (quiz.questionCount > 0) {
      navigate(featurePath, { state: { quizId: quiz.id } });
    } else {
      setNoQuestionsDialogOpen(true);
    }
  };

  const handleCloseNoQuestionsDialog = () => {
    setNoQuestionsDialogOpen(false);
  };

  const handleOpenDialog = (
    type,
    quiz = { name: "", description: "", id: "" }
  ) => {
    setDialogType(type);
    setEditQuiz(quiz);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditQuiz({ name: "", description: "", id: "" });
  };

  const handleSaveQuiz = () => {
    const quizData = {
      name: editQuiz.name,
      description: editQuiz.description,
      modifiedon: new Date(),
    };

    if (dialogType === "create") {
      db.collection("Quizlets")
        .doc(userid)
        .collection("myquiz")
        .add({
          ...quizData,
          createdon: new Date(), // Set createdon only when creating a new quiz
        });
    } else if (dialogType === "edit" && editQuiz.id) {
      db.collection("Quizlets")
        .doc(userid)
        .collection("myquiz")
        .doc(editQuiz.id)
        .update(quizData);
    }
    handleCloseDialog();
  };

  const handleOpenDeleteConfirm = (quizId) => {
    setQuizToDelete(quizId);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteQuiz = async (quizId) => {
    const quizRef = db
      .collection("Quizlets")
      .doc(userid)
      .collection("myquiz")
      .doc(quizId);
    const itemsRef = quizRef.collection("items");

    try {
      // First, delete all questions in the 'items' subcollection
      const snapshot = await itemsRef.get();
      const deletePromises = snapshot.docs.map((doc) => doc.ref.delete());
      await Promise.all(deletePromises);

      // Then, delete the quiz document itself
      await quizRef.delete();
      console.log("Quiz and its questions successfully deleted!");
    } catch (error) {
      console.error("Error removing quiz and questions: ", error);
    }
  };

  return (
    <>
      <Box sx={{ m: 1, p: 1 }}>
        <Button
          variant="contained"
          onClick={() => handleOpenDialog("create")}
          style={{ float: "right", marginTop: "10px", marginBottom: "20px" }}
        >
          Create New
        </Button>
        {loading ? (
          <LoadingScreen />
        ) : quizData.length > 0 ? (
          <Grid container spacing={3}>
            {quizData.map((quiz) => (
              <Grid item key={quiz.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%", // Ensure the card fills the container, so 'created on' can be at the bottom
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h5" component="div">
                        {quiz.name}
                      </Typography>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Typography variant="subtitle1">
                          {quiz.questionCount || 0} Questions
                        </Typography>
                        <Tooltip title="Items">
                          <IconButton
                            aria-label="items"
                            onClick={() =>
                              navigate("/dashboard/QuizEditor", {
                                state: { quizId: quiz.id },
                              })
                            }
                          >
                            <ListAltIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Typography variant="body2">
                      {quiz.description || "No description"}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <Tooltip title="Flashcard">
                      <IconButton
                        aria-label="flashcard"
                        onClick={() =>
                          handleFeatureAccess(quiz, "/dashboard/Flashcard")
                        }
                      >
                        <PlayCircleFilledIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Learn">
                      <IconButton
                        aria-label="learn"
                        onClick={() =>
                          handleFeatureAccess(quiz, "/dashboard/Learning")
                        }
                      >
                        <SchoolIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Test">
                      <IconButton
                        aria-label="test"
                        onClick={() =>
                          handleFeatureAccess(quiz, "/dashboard/FlashcardQuiz")
                        }
                      >
                        <QuizIcon />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                  <Box
                    sx={{
                      mt: "auto",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 2,
                    }}
                  >
                    <Typography variant="caption">
                      Last Modified:{" "}
                      {quiz.modifiedon
                        ? format(quiz.modifiedon, "MMM dd, yyyy")
                        : "N/A"}
                    </Typography>
                    <Box>
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleOpenDialog("edit", { ...quiz })}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleOpenDeleteConfirm(quiz.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" align="center" sx={{ mt: 5 }}>
            No Quiz is available. Please create a new Quiz Set.
          </Typography>
        )}

        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {dialogType === "create" ? "Create New Quiz" : "Edit Quiz"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Quiz Name"
              type="text"
              fullWidth
              value={editQuiz.name}
              onChange={(e) =>
                setEditQuiz({ ...editQuiz, name: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={editQuiz.description}
              onChange={(e) =>
                setEditQuiz({ ...editQuiz, description: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSaveQuiz}>
              {dialogType === "create" ? "Create" : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={noQuestionsDialogOpen}
          onClose={handleCloseNoQuestionsDialog}
        >
          <DialogTitle>No Questions Available</DialogTitle>
          <DialogContent>
            <Typography>
              Please use the "Items" button to add questions first.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNoQuestionsDialog}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this quiz?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
            <Button
              onClick={() => {
                handleDeleteQuiz(quizToDelete);
                setOpenDeleteConfirm(false);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default QuizList;
