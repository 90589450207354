import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardActions, AppBar, Toolbar, IconButton } from '@mui/material';
import { db } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import './FlashcardDisplay.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';

const FlashcardDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  var quizId = location.state?.quizId;
  console.log('QuizId: ' + quizId);

  const userId = useSelector((state) => state.user.userid);

  const [flashcards, setQuizQuestions] = useState([]);
  const [isFlipped, setIsFlipped] = useState([]);

  useEffect(() => {
    const fetchQuizQuestions = async () => {
      try {
        const collectionRef = db.collection('Quizlets').doc(userId).collection('myquiz').doc(quizId).collection('items');
        const snapshot = await collectionRef.get();
        const fetchedData = snapshot.docs.map(doc => ({
          id: doc.id,
          question: doc.data().question,
          answer: doc.data().answer
        }));
        setQuizQuestions(fetchedData);
        setIsFlipped(new Array(fetchedData.length).fill(false));
      } catch (error) {
        console.error('Error fetching quiz questions:', error);
      }
    };

    fetchQuizQuestions();
  }, [quizId]);

  const handleCardFlip = (index) => {
    setIsFlipped((prevFlipped) => {
      const updatedFlipped = [...prevFlipped];
      updatedFlipped[index] = !prevFlipped[index];
      return updatedFlipped;
    });
  };

  const handleBackToList = () => {
    navigate('/dashboard/QuizList'); // Replace '/quizlist' with the actual path to your QuizList component
  };

  console.log(flashcards);
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="end" color="inherit" onClick={handleBackToList}>
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="flashcardContainer" >
        <div className="cardContainer">
          {flashcards.map((flashcard, index) => (
            <Card key={flashcard.id} className={`card ${isFlipped[index] ? 'flipped' : ''}`}>
              <div className="cardInner" onClick={() => handleCardFlip(index)}>
                <div className="cardFront">
                  <CardContent>
                  <Typography variant="h4" color="text.secondary" component="div">
                    Question {index + 1} of {flashcards.length}
                  </Typography>
                  <Typography variant="h3" gutterBottom align="center">
                    <br />
                    {flashcard.question}
                  </Typography>
                  </CardContent>
                </div>
                <div className="cardBack">
                  <CardContent>
                  <Typography variant="h4" color="text.secondary" component="div">
                    Question {index + 1} of {flashcards.length}
                  </Typography>
                  <Typography variant="h3" gutterBottom align="center">
                    <br />
                    {flashcard.answer}
                  </Typography>
                  </CardContent>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default FlashcardDisplay;
