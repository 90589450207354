import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function QuestionEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const quizId = location.state?.quizId;
  const userId = useSelector((state) => state.user.userid);

  const [questionCount, setQuestionCount] = useState(0);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({
    id: "",
    question: "",
    answer: "",
  });

  useEffect(() => {
    const quizRef = db
      .collection("Quizlets")
      .doc(userId)
      .collection("myquiz")
      .doc(quizId);

    const unsubscribeQuiz = quizRef.onSnapshot((quizDoc) => {
      if (quizDoc.exists) {
        setQuestionCount(quizDoc.data().questionCount || 0);
      }
    });

    const unsubscribeQuestions = quizRef
      .collection("items")
      .onSnapshot((snapshot) => {
        const fetchedQuestions = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            // Ensure modifiedon is a Date object for sorting
            modifiedon: doc.data().modifiedon?.toDate
              ? doc.data().modifiedon.toDate()
              : new Date("1995-12-17T03:24:00"),
          }))
          // Sort by modifiedon in descending order
          .sort((a, b) => b.modifiedon - a.modifiedon);
        setQuizQuestions(fetchedQuestions);
      });

    return () => {
      unsubscribeQuiz();
      unsubscribeQuestions();
    };
  }, [quizId, userId]);

  const handleOpenDialogForNewQuestion = () => {
    setCurrentEdit({ id: "", question: "", answer: "" });
    setEditDialogOpen(true);
  };

  const handleSave = async () => {
    const quizRef = db
      .collection("Quizlets")
      .doc(userId)
      .collection("myquiz")
      .doc(quizId);

    if (currentEdit.id) {
      // Editing existing question
      try {
        await quizRef.collection("items").doc(currentEdit.id).update({
          question: currentEdit.question,
          answer: currentEdit.answer,
          modifiedon: new Date(),
        });
      } catch (error) {
        console.error("Error updating question:", error);
      }
    } else {
      // Adding new question
      try {
        await quizRef.collection("items").add({
          question: currentEdit.question,
          answer: currentEdit.answer,
          modifiedon: new Date(),
        });

        // After successfully adding a new question, update the questionCount
        const newQuestionCount = quizQuestions.length + 1; // Assuming quizQuestions is already up-to-date
        await quizRef.update({
          questionCount: newQuestionCount,
          modifiedon: new Date(),
        });
      } catch (error) {
        console.error(
          "Error adding new question or updating question count:",
          error
        );
      }
    }

    setEditDialogOpen(false);
    setCurrentEdit({ id: "", question: "", answer: "" }); // Reset current edit state
  };

  const deleteQuestion = async (questionId) => {
    await db
      .collection("Quizlets")
      .doc(userId)
      .collection("myquiz")
      .doc(quizId)
      .collection("items")
      .doc(questionId)
      .delete();
  };

  const handleBackToList = () => {
    navigate("/dashboard/QuizList");
  };

  const handleEditQuestion = (question) => {
    setCurrentEdit(question);
    setEditDialogOpen(true);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBackToList}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Question Editor
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6" gutterBottom>
          Total Questions: {questionCount}
        </Typography>

        <Box sx={{ m: 2, textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialogForNewQuestion}
          >
            Add New Question
          </Button>
        </Box>
        <Box sx={{ m: 2 }}>
          {quizQuestions.map((question) => (
            <Card key={question.id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">Q: {question.question}</Typography>
                <Typography color="textSecondary">
                  A: {question.answer}
                </Typography>
              </CardContent>
              <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
                <IconButton onClick={() => handleEditQuestion(question)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => deleteQuestion(question.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Card>
          ))}
        </Box>
        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>
            {currentEdit.id ? "Edit Question" : "Add New Question"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Question"
              type="text"
              fullWidth
              variant="outlined"
              value={currentEdit.question}
              onChange={(e) =>
                setCurrentEdit({ ...currentEdit, question: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Answer"
              type="text"
              fullWidth
              variant="outlined"
              value={currentEdit.answer}
              onChange={(e) =>
                setCurrentEdit({ ...currentEdit, answer: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default QuestionEditor;
