import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import BlogPage from "./pages/BlogPage";
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import ProductsPage from "./pages/ProductsPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import LearningPage from "./pages/LearningPage";
import FlashcardQuizPage from "./pages/FlashcardQuizPage";
import FlashcardPage from "./pages/FlashcardPage";
import QuizEditorPage from "./pages/QuizEditorPage";
import QuizListPage from "./pages/QuizListPage";
import LoadingScreen from "./components/LoadingScreen";
// ----------------------------------------------------------------------

export default function Router() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const checkingAuth = useSelector((state) => state.user.checkingAuth);

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/QuizList" />, index: true },
        { path: "QuizList", element: <QuizListPage /> },
        { path: "Learning", element: <LearningPage /> },
        { path: "Flashcard", element: <FlashcardPage /> },
        { path: "FlashcardQuiz", element: <FlashcardQuizPage /> },
        { path: "QuizEditor", element: <QuizEditorPage /> },
        { path: "app", element: <DashboardAppPage /> },
        { path: "user", element: <UserPage /> },
        { path: "products", element: <ProductsPage /> },
        { path: "blog", element: <BlogPage /> },
      ],
    },
    {
      path: "login",
      element: isAuthenticated ? (
        <Navigate to="/dashboard/QuizList" />
      ) : (
        <LoginPage />
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/QuizList" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  if (checkingAuth) {
    return <LoadingScreen />;
  }

  return routes;
}
