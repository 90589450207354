import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Stack
} from '@mui/material';
import { db } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import './FlashcardLearn.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux';

const FlashcardLearn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  var quizId = location.state?.quizId;
  console.log('QuizId: ' + quizId);
  const userId = useSelector((state) => state.user.userid);

  const [flashcards, setQuizQuestions] = useState([]);
  const [currentCard, setCurrentCard] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    const fetchQuizQuestions = async () => {
      try {
        const collectionRef = db
          .collection('Quizlets')
          .doc(userId)
          .collection('myquiz')
          .doc(quizId)
          .collection('items');
        const snapshot = await collectionRef.get();
        const fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          question: doc.data().question,
          answer: doc.data().answer,
        }));
        setQuizQuestions(fetchedData);
      } catch (error) {
        console.error('Error fetching quiz questions:', error);
      }
    };

    fetchQuizQuestions();
  }, [quizId]);

  const handleNextCard = () => {
    setCurrentCard((prevCard) => prevCard + 1);
    setUserAnswer('');
    setIsAnswerCorrect(null);
    setShowAnswer(false);
  };

  const handlePrevCard = () => {
    setCurrentCard((prevCard) => prevCard - 1);
    setUserAnswer('');
    setIsAnswerCorrect(null);
    setShowAnswer(false);
  };

  const handleCheckAnswer = () => {
    const correctAnswer = flashcards[currentCard].answer;
    const isCorrect =
      userAnswer.trim().toLowerCase() === correctAnswer.trim().toLowerCase();
    setIsAnswerCorrect(isCorrect);
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
  };

  const handleBackToList = () => {
    navigate('/dashboard/QuizList'); // Replace '/quizlist' with the actual path to your QuizList component
  };

  console.log(flashcards);
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="end" color="inherit" onClick={handleBackToList}>
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="flashcardLearnContainer">
        {currentCard < flashcards.length ? (
          <Card className="cardLearn">
            <div className="cardLearnInner">
              <div className="cardLearnFront">
                <CardContent>
                  <Typography variant="h4" color="text.secondary" component="div">
                    Question {currentCard + 1} of {flashcards.length}
                  </Typography>
                  <Typography variant="h3" gutterBottom align="center">
                    <br />
                    {flashcards[currentCard].question}
                  </Typography>
                  <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div className="answerContainer">
                      <TextField
                        label="Your Answer"
                        value={userAnswer}
                        onChange={(e) => setUserAnswer(e.target.value)}
                        variant="outlined"
                      />
                      <Button
                        className="checkButton"
                        onClick={handleCheckAnswer}
                        disabled={!userAnswer}
                      >
                        Check
                      </Button>
                    </div>
                    {isAnswerCorrect !== null && (
                      <Typography
                        variant="h6"
                        gutterBottom
                        align="center"
                        color={isAnswerCorrect ? 'success' : 'error'}
                      >
                        {isAnswerCorrect ? 'Correct' : 'Incorrect'}
                      </Typography>
                    )}
                    {showAnswer && (
                      <Typography variant="h6" color="text.secondary">
                        Answer: {flashcards[currentCard].answer}
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </div>
            </div>
            <CardActions className="learnbuttonContainer-right">
              <Stack direction="row" spacing={1} alignItems="center">
                <div className="buttonGroup">
                  {isAnswerCorrect === false && (
                    <Button
                      className="showAnswerButton"
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={handleShowAnswer}
                      size="small"
                    >
                      Answer
                    </Button>
                  )}
                  <Button
                    className="learnbutton"
                    onClick={handlePrevCard}
                    disabled={currentCard === 0}
                  >
                    Previous
                  </Button>
                  <Button
                    className="learnbutton"
                    onClick={handleNextCard}
                    disabled={currentCard === flashcards.length - 1}
                  >
                    Next
                  </Button>
                </div>
              </Stack>
            </CardActions>
          </Card>
        ) : (
          <Typography variant="h5">End of Quiz</Typography>
        )}
      </div>
    </>
  );
};

export default FlashcardLearn;
