import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";

import { createBrowserHistory } from "history";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { loginSuccess, logout, setCheckingAuth } from "./reducers/userSlice";

// ----------------------------------------------------------------------
const history = createBrowserHistory();

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        console.log("User is signed in", user);
        dispatch(
          loginSuccess({
            userid: user.uid,
            displayname: user.displayName,
            email: user.email,
          })
        );
      } else {
        // User is signed out
        console.log("User is signed out");
        dispatch(logout());
      }

      dispatch(setCheckingAuth(false));
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router history={history} />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
