import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardContent, CardActions, Typography, AppBar, Toolbar, IconButton, ToggleButton, ToggleButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { db } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import shuffle from 'lodash/shuffle';
import './FlashcardQuiz.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useSelector } from 'react-redux';

const FlashcardQuiz = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const quizId = location.state?.quizId;
  const userId = useSelector((state) => state.user.userid);
  const [flashcards, setQuizQuestions] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);

  useEffect(() => {
    const fetchQuizQuestions = async () => {
      try {
        const collectionRef = db
          .collection('Quizlets')
          .doc(userId)
          .collection('myquiz')
          .doc(quizId)
          .collection('items');
        const snapshot = await collectionRef.get();
        const fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          question: doc.data().question,
          answer: doc.data().answer,
        }));
        const shuffledAnswers = shuffle(fetchedData.map((flashcard) => ({
          answer: flashcard.answer
        })));


        const shuffledData = fetchedData.map((flashcard, index) => ({
          ...flashcard,
          display_answer: shuffledAnswers[index], // Shuffle the answer array while keeping "True" and "False" fixed
          correct_selection: flashcard.answer === shuffledAnswers[index].answer,
          user_selection: null,
        }));

        console.log("shuffledData" + JSON.stringify(shuffledData));

        setQuizQuestions(shuffledData);
      } catch (error) {
        console.error('Error fetching quiz questions:', error);
      }
    };

    fetchQuizQuestions();
  }, [quizId]);

  const handleBackToList = () => {
    navigate('/dashboard/QuizList'); // Replace '/quizlist' with the actual path to your QuizList component
  };

  const handleAnswerSelection = (index, answer) => {
    setQuizQuestions((prevFlashcards) => {
      const updatedFlashcards = [...prevFlashcards];
      updatedFlashcards[index].user_selection = answer;
      return updatedFlashcards;
    });
  }

  useEffect(() => {
    // Check if any question is unanswered (null value)
    const hasUnansweredQuestion = flashcards.some((card) => card.user_selection === null);

    // Enable/disable the submit button accordingly
    setSubmitDisabled(hasUnansweredQuestion);
  }, [flashcards]);

  const handleSubmit = () => {
    const correctAnswersCount = flashcards.reduce(
      (count, flashcard) =>
        flashcard.correct_selection === flashcard.user_selection ? count + 1 : count,
      0
    );
    setCorrectCount(correctAnswersCount);
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleShowReview = () => {
    setShowAnswers(true);
    setDialogOpen(false);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="end" color="inherit" onClick={handleBackToList}>
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="flashcardQuizContainer">
        {flashcards.map((flashcard, index) => (
          <Card key={flashcard.id} className="cardQuiz">
            <div className="cardQuizInner">
              <div className="cardQuizFront">
                <CardContent>
                  <Typography variant="h4" color="text.secondary" component="div">
                    Question {index + 1} of {flashcards.length}
                  </Typography>
                  <Typography variant="h3" gutterBottom align="center">
                    <br />
                    {flashcard.question}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom align="center">
                    <br />
                    Answer: {flashcard.display_answer.answer}</Typography>

                </CardContent>
              </div>
            </div>
            <CardActions className="quizButtonContainer-right">
              <ToggleButtonGroup
                exclusive
                value={flashcard.user_selection} // Set the value based on the selected answer
                onChange={(event, answer) => handleAnswerSelection(index, answer)} // Pass the index along with the answer
                className="answerToggleButtonGroup"
              >
                <ToggleButton
                  value={true}
                  className={`answerToggleButton ${flashcard.user_selection === true ? 'selected' : ''
                    } ${showAnswers && flashcard.correct_selection === false && flashcard.user_selection === true ? 'incorrect' : ''
                    }`}
                >
                  True
                </ToggleButton>
                <ToggleButton
                  value={false}
                  className={`answerToggleButton ${flashcard.user_selection === false ? 'selected' : ''
                    } ${showAnswers && flashcard.correct_selection === true && flashcard.user_selection === false ? 'incorrect' : ''
                    }`}
                >
                  False
                </ToggleButton>

              </ToggleButtonGroup>

            </CardActions>
          </Card>
        ))}
        <div className="submitButtonContainer">
          <Button
            variant="contained"
            color="primary"
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Quiz Results</DialogTitle>
        <DialogContent>
          <Typography>
            You answered {correctCount} out of {flashcards.length} questions correctly.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Retry
          </Button>
          <Button onClick={handleShowReview} color="primary">
            Review
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FlashcardQuiz;
